import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuard} from 'app/shared/auth/admin-auth.guard';
import { AuthGuard } from 'app/shared/auth/auth.guard';
//Route for content layout with sidebar, navbar and footer.

export const ADMIN_ROUTES: Routes = [

  {
    path: '',
    loadChildren: () => import('app/admin-accueil/admin-accueil.module').then(m => m.AdminAccueilModule),
    canActivate: [AdminAuthGuard]

  },
  {
    path: 'admin-accueil',
    loadChildren: () => import('app/admin-accueil/admin-accueil.module').then(m => m.AdminAccueilModule),
    canActivate: [AdminAuthGuard]

  },
  {
    path: 'admin-contenu',
    loadChildren: () => import('app/admin-contenu/admin-contenu.module').then(m => m.AdminContenuModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-clients',
    loadChildren: () => import('app/admin-clients/admin-clients.module').then(m => m.AdminClientsModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-audits',
    loadChildren: () => import('app/admin-audits/admin-audits.module').then(m => m.AdminAuditsModule),
    canActivate: [AdminAuthGuard]
  },
  //{
  //  path: 'admin-chat',
  //  loadChildren: 'app/admin-chat/admin-chat.module#AdminChatModule',
  //  canActivate: [AdminAuthGuard]
  // },
  {
    path: 'admin-documents',
    loadChildren: () => import('app/admin-documents/admin-documents.module').then(m => m.AdminDocumentsModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-mailings',
    loadChildren: () => import('app/admin-mailings/admin-mailings.module').then(m => m.AdminMailingsModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-questionnaires',
    loadChildren: () => import('app/admin-questionnaires/admin-questionnaires.module').then(m => m.AdminQuestionnairesModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-support',
    loadChildren: () => import('app/admin-support/admin-support.module').then(m => m.AdminSupportModule),
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-application',
    loadChildren: () => import('app/admin-application/admin-application.module').then(m => m.AdminApplicationModule),
    canActivate: [AdminAuthGuard]
  }  

];



export const CLIENT_ROUTES: Routes = [
 {
    path: '',
    loadChildren: () => import('app/client-accueil/client-accueil.module').then(m => m.ClientAccueilModule),
    canActivate: [AuthGuard]
  },
 
  {
    path: 'accueil',
    loadChildren: () => import('app/client-accueil/client-accueil.module').then(m => m.ClientAccueilModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'veille',
    loadChildren: () => import('app/client-veille/client-veille.module').then(m => m.ClientVeilleModule),
    canActivate: [AuthGuard]
 },
  {
    path: 'audits',
    loadChildren: () => import('app/client-audits/client-audits.module').then(m => m.ClientAuditsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stats',
    loadChildren: () => import('app/client-stats/client-stats.module').then(m => m.ClientStatsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('app/client-documents/client-documents.module').then(m => m.ClientDocumentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mailing',
    loadChildren: () => import('app/client-mailings/client-mailings.module').then(m => m.ClientMailingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'utilisateurs',
    loadChildren: () => import('app/client-support/client-support.module').then(m => m.ClientSupportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('app/client-support/client-support.module').then(m => m.ClientSupportModule),
    canActivate: [AuthGuard]
  },


];




/*

export const DEMO_ROUTES: Routes = [

    {
      path: 'dashboard',
      loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
    {
      path: 'calendar',
      loadChildren: './calendar/calendar.module#CalendarsModule'
    },
    {
      path: 'charts',
      loadChildren: './charts/charts.module#ChartsNg2Module'
    },
    {
      path: 'forms',
      loadChildren: './forms/forms.module#FormModule'
    },
    {
      path: 'maps',
      loadChildren: './maps/maps.module#MapsModule'
    },
    {
      path: 'tables',
      loadChildren: './tables/tables.module#TablesModule'
    },
    {
      path: 'datatables',
      loadChildren: './data-tables/data-tables.module#DataTablesModule'
    },
    {
      path: 'uikit',
      loadChildren: './ui-kit/ui-kit.module#UIKitModule'
    },
    {
      path: 'components',
      loadChildren: './components/ui-components.module#UIComponentsModule'
    },
    {
      path: 'pages',
      loadChildren: 'app/pages/full-pages/full-pages.module#FullPagesModule'
    },
    {
      path: 'cards',
      loadChildren: './cards/cards.module#CardsModule'
    },
    {
      path: 'colorpalettes',
      loadChildren: 'app/color-palette/color-palette.module#ColorPaletteModule'
    },
    {
      path: 'chat',
      loadChildren: './chat/chat.module#ChatModule'
    },
    {
      path: 'inbox',
      loadChildren: './inbox/inbox.module#InboxModule'
    },
    {
      path: 'taskboard',
      loadChildren: './taskboard/taskboard.module#TaskboardModule'
    },
    {
      path: 'player',
      loadChildren: './player/player.module#PlayerModule'
    }
  ];

  */