import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders } from 'app/services/commons';
import { saveAs } from 'file-saver';
import { AppConfig } from 'app/app.config';

/*
let faketree = {
  "data": [
    { "label": "Type de Veille 1", "position": null, "parentId": 0, "id": 1, "hasChildren": true, "transientParentId": 0, "level": 1 },
    { "label": "Type de Veille 2", "position": null, "parentId": 0, "id": 1202, "hasChildren": true, "transientParentId": 0, "level": 1 },
    { "label": "Type de Veille 3", "position": null, "parentId": 0, "id": 2403, "hasChildren": false, "transientParentId": 0, "level": 1 }],
  "totalCount": 3
};
*/

@Injectable({  providedIn: 'root' })
export class TaxonomieService {

  dataSource: any;
  applicablesDataSource: any;
  public restrictedRoots : any = [0,0,0];
  public filtrageProfil : any = [0,0,0];

//  getFakeTree(): any { return faketree.data; }

  moveNodeUp(noeud_id:any) {
    // rang : Déplacement vers le haut parmi les frères
    return this.http.get<any>(AppConfig.settings.api +"/api/taxo/moveup/"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
  }

  moveNodeDown(noeud_id:any) {
     // rang : Déplacement vers le bas parmi les frères
     console.log("CALLED service.moveNodeDown ")
     return this.http.get<any>(AppConfig.settings.api +"/api/taxo/movedown/"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
  }

  moveNodeTo(noeud_id:number, target_id:number) {
    // Si même type de noeud alors, on copie à côté (frère)
    // Si type de noeud père alors on ajoute comme fils à la fin des fils
    return this.http.get<any>(AppConfig.settings.api +"/api/taxo/moveto/"+ noeud_id + "/" + target_id, { headers: getHttpHeaders()}).toPromise();
  }


 copyNodeTo(noeud_id:number, target_id:number, withusages:boolean) {
    // Si même type de noeud alors, on copie à côté (frère)
    // Si type de noeud père alors on ajoute comme fils à la fin des fils
    return this.http.get<any>(AppConfig.settings.api +"/api/taxo/copyto/"+ noeud_id + "/" + target_id + "?usages="+withusages , { headers: getHttpHeaders()}).toPromise();
  }


  // A placer dans le service ReferenceUsageService, non ?
  espacerRangs(noeud_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/referenceusage/espacerangs/"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
  }


// this.http.get(url, { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => {

  trier(noeud_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/taxo/sort/"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
  }



  exporterTaxonomie(noeud_id:any) {
    return this.http.get(AppConfig.settings.api +"/api/taxo/export/"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => { 
      saveAs(response,"export-taxonomy.xlsx");
       }).catch(error => {
          console.log("ERROR=", error);
        });

  }
  exporterUsages() {
    return this.http.get(AppConfig.settings.api +"/api/taxo/export/usages/all" , { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => { 
      saveAs(response,"export-usages.xlsx");
       }).catch(error => {
          console.log("ERROR=", error);
        });

  }



  constructor(private http: HttpClient) {

    var restrictedParents = this.restrictedRoots;
    var filtrageProfil = this.filtrageProfil;

    let SERVICE_URL = AppConfig.settings.api + "/api/taxo/";

    // https://js.devexpress.com/Documentation/Guide/Widgets/TreeList/Data_Binding/Custom_Sources/
    // https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/Methods/#updatekey_values
    this.dataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any)  : any {
        let params = "";
        let filter = "";

        if(AppConfig.settings.trace) {
          console.log("taxoDS : restrictedParents=" + restrictedParents );
          console.log("taxoDS : filtrageProfil=" + filtrageProfil );
        }
        if (loadOptions.parentIds) {
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list
        }
          if(AppConfig.settings.trace) console.log("taxoDS loadOptions=", loadOptions);
     
        if (restrictedParents[0] && loadOptions.parentIds && loadOptions.parentIds[0]==0) {
        //  console.log("HIT !");
          params = "children/0?retain=" + restrictedParents[0].join(",");
        }
        else
        if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          if (restrictedParents[0])
            filter=filter+"&retain=" + restrictedParents[0].join(",");
        }

        if(AppConfig.settings.trace)
         console.log("load: calling url: " + SERVICE_URL + params + filter);

        return http.get<any>(SERVICE_URL + params + filter, { headers: getHttpHeaders()}).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)
             console.log("------> Taxonomie/Noeud SERVER RESPONSE: ", response.data);
            // Post processing if loadOptions.filter[0] == "restrictedParents"
            /*
            if (loadOptions.filter && loadOptions.filter[0] == "restrictedParents") {
              var result = [];
              for ( var i = 0; i < response.data.length; i++) {
                var node = response.data[i];
                if(loadOptions.filter[2].indexOf(node.id)>-1) {
                  result.push(node);
                }
              }
              response.data = result;
              console.log("------> Taxonomie/Noeud2222 SERVER RESPONSE: ", response.data);
            }
            */
            /*
           for ( var i = 0; i < response.data.length; i++) {
            var node = response.data[i];
            if(node.sousdomaine) node.noeud=node.sousdomaine;
            else  if(node.domaine) node.noeud=node.domaine;
            else  if(node.section) node.noeud=node.section;
            }
*/
            return response.data;
          }).catch(error => {
            return []
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},

      byKey: function (key) {
     // console.log("byKey: " + SERVICE_URL +encodeURIComponent(key));
        return http.get(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
      },



      insert: function (values:any) {
        if (!values.parentId) // Need a dialog box or parent should be the currently selected node
          values.parentId = 0;
        values.label = values.label + "||||" + values.parentId;
        if(AppConfig.settings.trace)  console.log("CREATION: ", values);
        if(AppConfig.settings.trace) console.log("insert: post " + SERVICE_URL);
        return http.post<any>(SERVICE_URL, values,{ headers: getHttpHeaders()}).toPromise().then(response => {
          values.id = response.key;
          
          return {
            values: response.values,
            key: response.key
          }
        });
      },



      update: function (key, values) {
        if(AppConfig.settings.trace) console.log("update put: " + SERVICE_URL + "/" + encodeURIComponent(key));
        return http.put<any>(SERVICE_URL  + encodeURIComponent(key), values, { headers: getHttpHeaders()}).toPromise().then(response => {
          notify('Mise à jour effectuée');
          return {
            result: response.result,
            key: response.key
          }
        });

      },



      remove: function (key) {
        // Need to refresh cache after that ?
        if(AppConfig.settings.trace) console.log("remove delete: " + SERVICE_URL + "/" + encodeURIComponent(key));
        return http.delete<any>(SERVICE_URL  + encodeURIComponent(key),  { headers: getHttpHeaders()}).toPromise();
      }
    });

  }


  getRestDataSource() {
    return this.dataSource;
  }








}
