import { Component, OnInit, ElementRef, Directive } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { on, trigger, off } from "devextreme/events";

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {
    // "king-yna" for admins
    // "white" for clients
    sidebarbgcolor : string = "white" ; 

    constructor(private elementRef: ElementRef, private userService: UserService) { 

        if(this.userService.isAdminUserPersisted())
            this.sidebarbgcolor = "qse-admin" ;  
        else
            this.sidebarbgcolor = "white";

    }

    ngOnInit() {
       

        //sidebar toggle event listner
        this.elementRef.nativeElement.querySelector('#sidebarToggle')
            .addEventListener('click', this.onClick.bind(this));
        //customizer events
        //this.elementRef.nativeElement.querySelector('#cz-compact-menu').addEventListener('click', this.onClick.bind(this));
        //this.elementRef.nativeElement.querySelector('#cz-sidebar-width').addEventListener('click', this.onClick.bind(this));

        // window.addEventListener("resize", e => {
        //     alert("RESIZE")
        // })
        // window.addEventListener("scroll", e => {
        //     alert(`SCROLL: ${e.composedPath()} | ${e.currentTarget}`)
        //     e.srcElement.removeEventListener
        //     e.preventDefault()
        // })
        // window.addEventListener("DOMMouseScroll", e => {
        //     alert("WHEEL")
        //     //e.preventDefault()
        // })
        // on(document.getElementById('main'), "dxpointerdown", e => {
        //     alert("dxpointerdown")
        //     document.getElementById('main').removeEventListener('scroll', () => {alert('removed')})
        //     e.preventDefault()
        //     return false
        // })
    }

    onClick(event) {
        //initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

}