import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders, replaceDateToISOString } from 'app/services/commons';
import { AppConfig } from 'app/app.config';

@Injectable()
export class AdministrateurNotificationsService {

  dataSource: any;
  private trace: boolean = true;
 
  constructor(private http: HttpClient) {
    let SERVICE_URL = AppConfig.settings.api + "/api/administrateurnotification/";

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any): any {
          let filter = "?filter=1=1";
        
         
          if (loadOptions.filter) {
            replaceDateToISOString(loadOptions.filter);
            //console.log("notif FILTER=", loadOptions.filter);
              filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }
        
          if (loadOptions.skip) {
            filter = filter + "&skip=" + loadOptions.skip;
          }
          else {
            filter = filter + "&skip=0";
          }

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }

      //     console.log("AdministrateurNotificationsService load: calling url: " + SERVICE_URL + filter);
          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders()}).toPromise()
            .then(response => {
           //  console.log("---> AdministrateurNotificationsService SERVER RESPONSE: ", response);
              return response;
            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
          //if (this.trace) console.log("byKey: " + SERVICE_URL   + encodeURIComponent(key));
          return http.get(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
        },


        insert: function (values:any) {
        //console.log("CREATION AdministrateurNotificationsService: ", values);
          // Need to create user (password, email)
         // console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders()}).toPromise().then(response => {
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },


        update: function (key, values) {
         // console.log("AdministrateurNotificationsService update put: " + SERVICE_URL   + encodeURIComponent(key));
         // console.log("update values:", values);
          return http.put<any>(SERVICE_URL   + encodeURIComponent(key), values, { headers: getHttpHeaders()}).toPromise().then(response => {
            return {
              result: response.result,
              key: response.key
            }
          });
        },



        remove: function (key) {
          // Need to refresh cache after that ?
         // if (this.trace) console.log("AdministrateurNotificationsService remove delete: " + SERVICE_URL  + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL   + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
        }
      }

    );

  }




  getRestDataSource() {
    return this.dataSource;
  }



}
