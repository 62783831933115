<div>


  <p>search-engine works!</p>
  <button (click)="initIndexesButtonOnClick($event)"> Init Indexes ! </button>
  <!--
  <button (click)="loadExigencesVersions()"> Load exigenceVersions ! </button>
  <button (click)="loadReferencesTextSearch()"> Load references ! </button>
  <button (click)="textSearchBySiteId()"> textSearchBySiteId ! </button>
  <button (click)="addEv()"> addEV ! </button>
  <input [(ngModel)]="pattern">
-->

</div>
