import { Component, OnInit } from '@angular/core';
import { SearchEngineService } from 'app/services/search-engine.service';


@Component({
  selector: 'app-search-engine',
  templateUrl: './search-engine.component.html',
  styleUrls: ['./search-engine.component.scss']
})
export class SearchEngineComponent implements OnInit {

  pattern : String;

  constructor(

    private searchEngineService: SearchEngineService

  ) { }

  ngOnInit(): void {

    this.pattern = ''
  }



  initIndexesButtonOnClick(event) {

    console.log('init indexes');
    console.log(event);


    this.searchEngineService.initIndexes()

  }

  loadExigencesVersions() {

    console.log(this.pattern);

    this.searchEngineService.loadExigencesVersions(this.pattern)


  }

  loadReferencesTextSearch() {

    console.log(this.pattern);
    this.searchEngineService.loadReferencesTextSearch(this.pattern)

  }

  textSearchBySiteId() {

    console.log(this.pattern);
    this.searchEngineService.textSearchBySiteId(0 , this.pattern)

  }

  addEv() {

    console.log('zouzou');
    this.searchEngineService.addEv()

  }

}
