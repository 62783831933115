import { Component, OnInit } from '@angular/core';
import { ROUTES_A, ROUTES_U } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UserService, QSE_USER_INFO } from 'app/services/user.service';
import { Subscription } from 'rxjs';
import { SiteChangeService } from 'app/site-change.service';
import { SiteService } from 'app/services/site.service';
import { comptageTexte, userHeaderInfo } from 'app/services/commons';
import { AppConfig } from 'app/app.config';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    logofile : string = AppConfig.settings.url_logo;
    homelink: any = ["/accueil"];
    public menuItems: any[];
    adminMode: boolean = false;
    currentSite: any = undefined;
    marque : string = AppConfig.settings.nomAppli;

    changeSiteSubscription: Subscription;

    constructor(private router: Router, private userService: UserService,
        private route: ActivatedRoute, public translate: TranslateService,
        private siteChangeService: SiteChangeService,
        private siteService: SiteService) {

            this.adminMode = this.userService.isAdminUserPersisted();
            if(this.adminMode) {
               // this.logofile = "assets/img/logo.png";
                this.logofile = AppConfig.settings.url_logo_admin;
                this.homelink = ["/admin-accueil"];
            } else {
                var QUI = JSON.parse(sessionStorage.getItem(QSE_USER_INFO));
                if(QUI.partenaireInfo!=null && QUI.partenaireInfo.marqueblanche) {
                    this.marque = "   "+QUI.partenaireInfo.nom;
                    this.logofile = QUI.partenaireInfo.logo;
                }

              
                if( userHeaderInfo[2])
                this.siteService.getComptesReferencePourSite( userHeaderInfo[2]).then(r=> {
                  // A remettre par la suite pour gérer le total des cas
                 this.updateBadgesForVeille(r.applicables, r.nouveaux, r.modifies, r.abroges, r.futurs, r.fiches);
                 comptageTexte[0] = r.applicables;
                 comptageTexte[1] = r.nouveaux;
                 comptageTexte[2] = r.modifies;
                 comptageTexte[3] = r.abroges;
                 comptageTexte[4] = r.futurs;
               
                });


                this.changeSiteSubscription = this.siteChangeService.getSiteChangeMessage().subscribe(site => {
                    this.currentSite = site;

                   this.siteService.getComptesReferencePourSite(site).then(r=> {
                     // A remettre par la suite pour gérer le total des cas
                    this.updateBadgesForVeille(r.applicables, r.nouveaux, r.modifies, r.abroges, r.futurs, r.fiches);
                    comptageTexte[0] = r.applicables;
                    comptageTexte[1] = r.nouveaux;
                    comptageTexte[2] = r.modifies;
                    comptageTexte[3] = r.abroges;
                    comptageTexte[4] = r.futurs;
                  
                   });
            });
        }
    }

    ngOnInit() {

        $.getScript('./assets/js/app-sidebar.js');
      //  $.getScript('./assets/js/vertical-timeline.js');
        // Ici, selon le type d'utilisateur (client versus admin) et son niveau de droits, on affichera un menu différents
        if (this.userService.isAdminUserPersisted()) {
            if(!AppConfig.settings.eprtn &&  ROUTES_A[3].submenu.length==4) {
                ROUTES_A[3].submenu.pop();
            }
            if(!AppConfig.settings.elegi &&  ROUTES_A[1].submenu.length==6) {
                ROUTES_A[1].submenu.pop();
            }

            this.menuItems = ROUTES_A.filter(menuItem => menuItem);
        }
        else
            this.menuItems = ROUTES_U.filter(menuItem => menuItem);



    }

    ngOnDestroy() {
        if(this.changeSiteSubscription)
         this.changeSiteSubscription.unsubscribe();
    }

    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }




    updateBadgeForAdminChat(value: string) {
        // '/admin-chat'
        this.menuItems[9].badge = value;
    }
    updateBadgeForAdminSupport(value: string) {
        // '/admin-support'
        this.menuItems[8].badge = value;
    }





    updateBadgeForClientSupport(value: string) {
        // '/support'
        this.menuItems[5].badge = value;
    }


    // A remettre par la suite pour gérer le total des cas
    updateBadgesForVeille(envigueur, nouveaux, modifies, abroges, futurs, fiches ) {
      // '/Veille (total en vigueur)'
      // if(! this.menuItems[1]) return;
      // if(! this.menuItems[1].submenu[4]) return;
      // this.menuItems[1].badge = String(total);
      // this.menuItems[1].submenu[0].badge = String(total);
      // this.menuItems[1].submenu[1].badge = String(envigueur);
      // this.menuItems[1].submenu[2].badge = String(nouveaux);
      // this.menuItems[1].submenu[3].badge = String(modifies);
      // this.menuItems[1].submenu[4].badge = String(abroges);
      // this.menuItems[1].submenu[5].badge = String(futurs);
      // if(this.menuItems[1].submenu[6])
      //     this.menuItems[1].submenu[6].badge = String(fiches);

      if(! this.menuItems[1]) return;
      if(! this.menuItems[1].submenu[4]) return;
      this.menuItems[1].badge = String(envigueur);
      this.menuItems[1].submenu[0].badge = String(envigueur);
      this.menuItems[1].submenu[1].badge = String(nouveaux);
      this.menuItems[1].submenu[2].badge = String(modifies);
      this.menuItems[1].submenu[3].badge = String(abroges);
      this.menuItems[1].submenu[4].badge = String(futurs);
      if(this.menuItems[1].submenu[5])
          this.menuItems[1].submenu[5].badge = String(fiches);

    }

}
