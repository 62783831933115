<router-outlet></router-outlet>

<dx-popup class="popup" [width]="500" [height]="350" [showTitle]="true" title="Inactivité détectée" [dragEnabled]="false"
[hideOnOutsideClick]="false" [visible]="showTimeoutDialog">
<div *dxTemplate="let data of 'content'">
   
   <h3> {{idleState}} </h3>

  <dx-button text="Déconnecter" type="danger" (onClick)="logout()"></dx-button>&nbsp;
  <dx-button text="Rester" type="normal" (onClick)="stay()"></dx-button>

</div>

</dx-popup>