import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { getHttpHeaders } from '../services/commons';

@Injectable({
  providedIn: 'root'
})




export class SearchEngineService {
  SERVICE_URL: string;

  constructor(private http: HttpClient) {

    this.SERVICE_URL = AppConfig.settings.api + "/api/search/";

  }


  initIndexes() {

    console.log(getHttpHeaders());


    // let init =  this.http.get(this.SERVICE_URL + 'initIndexes', { headers:  getHttpHeaders() }).toPromise();
    let init =  this.http.get(this.SERVICE_URL + 'initIndexes').toPromise();

    console.log(init);


  }

  loadExigencesVersions(pattern) {


    // let init =  this.http.get(this.SERVICE_URL + 'exigencesVersions', { headers:  getHttpHeaders() }).toPromise();
    let exVersions =  this.http.get(this.SERVICE_URL + 'exigencesVersions/' + pattern).toPromise().then(
      res =>
      {
        console.log(res);

      }
    );

    // console.log(exVersions);


  }


  loadReferencesTextSearch(pattern) {


    // let init =  this.http.get(this.SERVICE_URL + 'exigencesVersions', { headers:  getHttpHeaders() }).toPromise();
    let references =  this.http.get(this.SERVICE_URL + 'reference/' + pattern).toPromise().then(
      res =>
      {
        console.log(res);

      }
    );

    // console.log(references);


  }

  textSearchBySiteId(siteId, pattern) {

    // let refUsages: any;
    // let init =  this.http.get(this.SERVICE_URL + 'exigencesVersions', { headers:  getHttpHeaders() }).toPromise();
    let refUsages =  this.http.get(this.SERVICE_URL + 'textSearchBySiteId/' + siteId + '/' + pattern).toPromise().then(


      res => {
        return res['data']
      }
        // console.log(res);
        // refUsages = res
        // return res

      );

    return refUsages
  }


  addEv() {


    let init =  this.http.get(this.SERVICE_URL + 'addEV', { headers:  getHttpHeaders() }).toPromise();
    // console.log(init);


  }





}
