import { Injectable } from '@angular/core';
import { UserService } from 'app/services/user.service';

@Injectable({  providedIn: 'root' })
export class AdminChatService {

  constructor(private userService: UserService) {

    


   }

}
