import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "app/app.config";
import { getHttpHeaders } from "app/services/commons";

@Injectable()
export class ConnectAsService {

    constructor(private http: HttpClient){}

    generateTmpPassword(userName: string): any {
        return this.http.get(AppConfig.settings.api + "/oauth/genpwd/" + userName,{ headers: getHttpHeaders(), responseType: 'text'} ).toPromise()
    }

}