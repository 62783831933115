import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data


export const ROUTES_A: RouteInfo[] = [


  { path: '/admin-accueil', title: 'sidebar-admin*Accueil', icon: 'icon-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '', title: 'sidebar-admin*Contenu', icon: 'icon-layers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin-contenu/textes', title: 'sidebar-admin*Textes', icon: 'icon-docs', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-contenu/fiches-eclairage', title: "sidebar-admin*Fiches d'éclairage", icon: 'icon-paper-clip', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-contenu/taxonomie', title: 'sidebar-admin*Taxonomie', icon: 'icon-share', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-contenu/commentaires', title: 'sidebar-admin*Commentaires', icon: 'ft-edit-3', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-contenu/codes', title: 'sidebar-admin*Codes', icon: 'ft-package', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-contenu/legi-acces', title: 'sidebar-admin*LEGI', icon: 'ft-eye', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/admin-questionnaires/questionnaires', title: 'sidebar-admin*Questionnaires', icon: 'ft-check-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    

  {
    path: '', title: 'sidebar-admin*Clients', icon: 'icon-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/admin-clients/gestion', title: 'sidebar-admin*Gestion', icon: 'icon-book-open', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-clients/acces', title: 'sidebar-admin*Accès', icon: 'icon-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-clients/profil', title: 'sidebar-admin*Profils', icon: 'ft-triangle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-clients/partenaires', title: 'sidebar-admin*Partenaires', icon: 'icon-link', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  { path: '/admin-audits', title: 'sidebar-admin*Audit', icon: 'ft-thumbs-up', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


  {
    path: '', title: 'sidebar-admin*Mails', icon: 'icon-envelope-letter', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/admin-mailings/lettre', title: 'sidebar-admin*Lettre', icon: 'icon-book-open', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-mailings/envois', title: 'sidebar-admin*Envois', icon: 'ft-edit-3', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-mailings/parametres-mails', title: 'sidebar-admin*Paramètres', icon: 'ft-paperclip', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-mailings/archive-mails', title: 'sidebar-admin*Archive', icon: 'ft-folder', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },

  {
    path: '/', title: 'sidebar-admin*Documents', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/admin-documents/gestion-documents', title: 'sidebar-admin*Accès', icon: 'icon-folder', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-documents/quotas-clients', title: 'sidebar-admin*Quotas', icon: 'icon-info', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  { path: '/admin-support', title: 'sidebar-admin*Support', icon: 'icon-question', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
  //   { path: '/admin-chat', title: 'Entre nous', icon: 'icon-bubbles', class: '', badge: '3', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
  {
    path: '', title: 'sidebar-admin*Application', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/admin-application/administrateurs', title: 'sidebar-admin*Administrateurs', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-application/maintenance', title: 'sidebar-admin*Maintenance', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-application/statistiques', title: 'sidebar-admin*Stats', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-application/connexions', title: 'sidebar-admin*Connexions', icon: 'ft-activity', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-application/journaux', title: 'sidebar-admin*Journaux', icon: 'fa-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin-application/fusion', title: 'sidebar-admin*Fusion', icon: 'icon-magic-wand', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  }
];


//TRANSLATE TODO
export const ROUTES_U: RouteInfo[] = [

  { path: '/accueil', title: 'sidebar-user*Accueil', icon: 'icon-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '', title: 'sidebar-user*Veille', icon: 'icon-eye', class: 'has-sub', badge: '...', badgeClass: 'badge badge-pill badge-green badge-white-font float-right mr-1 mt-1 badge-max', isExternalLink: false,
    submenu: [
      // { path: '/veille/recherche', title: 'Recherche', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-green badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/applicables', title: 'sidebar-user*Applicables', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-green badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/nouveaux', title: 'sidebar-user*Nouveaux', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-deep-orange badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/modifies', title: 'sidebar-user*Modifiés', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-purple badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/abroges', title: 'sidebar-user*Abrogés', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-brown  badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/futurs', title: 'sidebar-user*Futurs', icon: '', class: '', badge: '...', badgeClass: 'badge badge-pill badge-indigo badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/fiches', title: "sidebar-user*Fiches d'éclairage", icon: '', class: '', badge: '?', badgeClass: 'badge badge-pill badge-yellow badge-black-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/veille/revue', title: 'sidebar-user*Revue', icon: '', class: '', badge: '', badgeClass: 'badge badge-pill badge-yellow badge-black-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'sidebar-user*Audit', icon: 'ft-thumbs-up', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/audits/eval/', title: 'sidebar-user*Gestion des audits', icon: '', class: '', badge: '', badgeClass: 'badge badge-pill badge-green badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
      { path: '/audits/responsables', title: 'sidebar-user*Responsable d\'actions', icon: '', class: '', badge: '', badgeClass: 'badge badge-pill badge-deep-orange badge-white-font float-right mr-1 mt-1', isExternalLink: false, submenu: [] },

    ]
  },
  { path: '/stats', title: 'sidebar-user*Statistiques', icon: 'icon-graph', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/documents', title: 'sidebar-user*Documents', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

  { path: '/mailing', title: 'sidebar-user*Mailing', icon: 'ft-mail', class: '', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
  { path: '/support/utilisateurs', title: 'sidebar-user*Utilisateurs', icon: 'icon-users', class: '', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, submenu: [] },
  { path: '/support', title: 'sidebar-user*Support', icon: 'icon-question', class: '', badge: '', badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1', isExternalLink: false, submenu: [] }

];

