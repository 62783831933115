import { Injectable } from '@angular/core';



/**
 * Service repsonsable de recevoir de la part du serveur des notifications pour le pilotage de l'application.
 * Exemple : 
 *    - mise à jour de la page (refresh)<br>
 *    - mise à jour d'un éléments dans la page (badge) <br>
 *    - ajout d'une notification à destination d'un administrateur ou d'un utilisateur
 *    - mise à jour de données dans une table <br>
 *    - navigation sur une autre page <br>
 *    - blocable momentané de l'interaction avec la page le temps d'une opération de mise à jour<br>
 *    - message/notification affichée au centre de l'écran pour prévenir l'utilisateur. <br>
 *    - ...
 */
@Injectable({  providedIn: 'root' })
export class MessageBusService {

  constructor() { }

}
