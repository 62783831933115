import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders } from 'app/services/commons';
import { AppConfig } from 'app/app.config';


export class Administrateur {
  id: number;
  prenom: string;
  nom: string;
  telephone: string;
  email: string;
  password: string;
  actif: boolean;
}

let _droitsAdminMap: { [droits: number]: string } = {
  0: "SUPERADMIN",
  1: "ADMIN",
  2: "EDITEUR",
  3: "AUDITEUR",
  4: "STAGIAIRE",
  5: "INVITE",
  6: "PARTENAIRE"
};

let _droitsDataSource = [
  { value: 0, label: "SUPERADMIN" },
  { value: 1, label: "ADMIN" },
  { value: 2, label: "EDITEUR" },
  { value: 3, label: "AUDITEUR" },
  { value: 4, label: "STAGIAIRE" },
  { value: 5, label: "INVITE" },
  { value: 6, label: "PARTENAIRE" },
]

@Injectable({  providedIn: 'root' })
export class AdministrateurService {

  dataSource: any;
  actifsDataSource: any;



marquerDernierAccesPageAccueilAdmin(admin_id: any) {
  var clef: string = "A" + admin_id   + "_DACCES_ACCUEIL";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}



marquerDernierAccesClocheNotifAdmin(admin_id: any) {
  var clef: string = "A" + admin_id   + "_DACCES_CLOCHENOTIF";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}


marquerDernierAccesPageSupportAdmin(admin_id: any) {
  var clef: string = "A" + admin_id   + "_DACCES_SUPPORT";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}

  constructor(private http: HttpClient) {

    let SERVICE_URL = AppConfig.settings.api + "/api/administrateur/";

    // https://js.devexpress.com/Documentation/Guide/Widgets/TreeList/Data_Binding/Custom_Sources/
    // https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/Methods/#updatekey_values
    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any) :any {
          let filter = "";
        //  console.log("AdministrateurService", loadOptions);
        //  console.log("filter", loadOptions.filter);

          if (loadOptions.filter) {
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }

         // console.log("load: calling url: " + SERVICE_URL + filter);

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders()}).toPromise()
            .then(response => {
              for ( var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                obj.fullname = obj.prenom + " " + obj.nom;
              }
             // console.log("AdministrateurService ---------------> SERVER RESPONSE: ", response);
              return response.data
            }).catch(function (err) {
              console.log(err);
              notify("load ERREUR: " + err);
            });;
        },


        byKey: function (key) {
        if(AppConfig.settings.trace)  console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get<any>(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise().then(response => {
            response.fullname = response.prenom + " " + response.nom;
            return response;
          }).catch(function (err) {
            console.log(err);
            notify("byKey ERREUR: " + err);
          });
        },



        insert: function (values) {
          // console.log("CREATION: ", values);
          // Need to create user (password, email)
          console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders()}).toPromise().then(response => {
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            // Selon l'erreur, afficher un message différent
            // si ERREUR 409 : l'email existe déjà, création refusée
            console.log(err);
            notify("insert ERREUR: " + err);
            // ou dialogue modal ?
          });
        },



        update: function (key, values) {
        //  console.log("update put: " + SERVICE_URL  + encodeURIComponent(key));
        //  console.log("update values:", values);

          return http.put<any>(SERVICE_URL  + encodeURIComponent(key), values, { headers: getHttpHeaders()}).toPromise().then(response => {
            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          }).catch(function (err) {
            // Selon l'erreur, afficher un message différent
            // si ERREUR 409 : l'email existe déjà, création refusée
            console.log(err);
            notify("UPDATE ERREUR: " + err);
            // ou dialogue modal ?
          });
        },


        remove: function (key) {
          // Need to refresh cache after that ?
         // console.log("remove delete: " + SERVICE_URL + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise().catch(function (err) {
            console.log(err);
            notify("DELETE ERREUR: " + err);
          });
        }
      }
          );










          this.actifsDataSource = new CustomStore(
            {
              key: "id",
      
              load: function (loadOptions: any) :any {
                let filter = "";
              //  console.log("AdministrateurService", loadOptions);
              //  console.log("filter", loadOptions.filter);
      
                if (loadOptions.filter) {
                  filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
                }
                
      
               // console.log("load: calling url: " + SERVICE_URL + filter);
      
                return http.get<any>(SERVICE_URL + "actifs/" + filter, { headers: getHttpHeaders()}).toPromise()
                  .then(response => {
                    for ( var i = 0; i < response.data.length; i++) {
                      var obj = response.data[i];
                      obj.fullname = obj.prenom + " " + obj.nom;
                    }
                   // console.log("AdministrateurService ---------------> SERVER RESPONSE: ", response);
                    return response.data
                  }).catch(function (err) {
                    console.log(err);
                    notify("load ERREUR: " + err);
                  });;
              },
      
      
              byKey: function (key) {
              if(AppConfig.settings.trace)  console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
                return http.get<any>(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise().then(response => {
                  response.fullname = response.prenom + " " + response.nom;
                  return response;
                }).catch(function (err) {
                  console.log(err);
                  notify("byKey ERREUR: " + err);
                });
              },
      
      
      
              insert: function (values) {
                // console.log("CREATION: ", values);
                // Need to create user (password, email)
                console.log("insert: post " + SERVICE_URL);
                return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders()}).toPromise().then(response => {
                  return {
                    values: response.values,
                    key: response.key
                  }
                }).catch(function (err) {
                  // Selon l'erreur, afficher un message différent
                  // si ERREUR 409 : l'email existe déjà, création refusée
                  console.log(err);
                  notify("insert ERREUR: " + err);
                  // ou dialogue modal ?
                });
              },
      
      
      
              update: function (key, values) {
              //  console.log("update put: " + SERVICE_URL  + encodeURIComponent(key));
              //  console.log("update values:", values);
      
                return http.put<any>(SERVICE_URL  + encodeURIComponent(key), values, { headers: getHttpHeaders()}).toPromise().then(response => {
                  notify('Mise à jour effectuée');
                  return {
                    result: response.result,
                    key: response.key
                  }
                }).catch(function (err) {
                  // Selon l'erreur, afficher un message différent
                  // si ERREUR 409 : l'email existe déjà, création refusée
                  console.log(err);
                  notify("UPDATE ERREUR: " + err);
                  // ou dialogue modal ?
                });
              },
      
      
              remove: function (key) {
                // Need to refresh cache after that ?
               // console.log("remove delete: " + SERVICE_URL + encodeURIComponent(key));
                return http.delete<any>(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise().catch(function (err) {
                  console.log(err);
                  notify("DELETE ERREUR: " + err);
                });
              }
            }
                );
  }

  getDroitDataSource() {
    return _droitsDataSource;
  }

  getRestDataSource() {
    return this.dataSource;
  }


  getActifsRestDataSource() {
    return this.actifsDataSource;
  }
}