import { Injectable } from "@angular/core";
import { EvaluationExigenceService } from "app/services/evaluation-exigence.service";

@Injectable()
export class EvaluationExigenceFormService {

    constructor(public eeService: EvaluationExigenceService){}

    async save(eeModel: any){
        // TODO: manage possible reponse error on update !!!
        let _res =  await this.eeService.getEvaluationExigencesRestDataSource().update(eeModel.id, eeModel);
        return _res != null ? _res.result : {}
    }

    async notifiyOpportunityResponsible(eeModel: any){
        return this.eeService.notifierResponsableOpportunite(eeModel.id)
    }

    async notifiyResponsible(eeModel: any){
        return this.eeService.notifierResponsable(eeModel.id);
    }

}
