import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const isUtilisateur = this.auth.isUtilisateurUserPersisted();
      const isAdmin = this.auth.isAdminUserPersisted();
    if(AppConfig.settings.trace)  console.log("AuthGuard:canActivate, isUtilisateur="+isUtilisateur);
    if (isUtilisateur && !isAdmin) {

     return true;
    } else {
      this.router.navigate(['pages/login'], {queryParams: {redirectTo: state.url}});
      return false;
    }

  }
}
