export const environment = {
  name: 'prod',
  production: true,
//  api: 'https://preprod.qse-veille.fr',
 // api: 'http://ns367600.ip-94-23-26.eu:9999',
  /* api:  'http://localhost:8080', */
};
// https://stackblitz.com/edit/angular-window-hostname
// https://stackoverflow.com/questions/36222845/how-to-get-domain-name-for-service-in-angular2
//. https://juristr.com/blog/2018/01/ng-app-runtime-config/#runtime-configuration
// https://juristr.com/blog/2018/01/ng-app-runtime-config/
//*** */ https://www.jvandemo.com/how-to-use-environment-variables-to-configure-your-angular-application-without-a-rebuild/




// https://stackoverflow.com/questions/53517864/dynamic-url-by-setting-environment-variables-in-angular-6
