import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { getHttpHeaders } from './commons';

@Injectable({
  providedIn: 'root'
})
export class ProgressionService {

 // let SERVICE_URL = AppConfig.settings.api + "/api/waiter/";

newtask() {
  return this.http.get<any>(AppConfig.settings.api + "/api/waiter/newtask/", { headers: getHttpHeaders() }).toPromise();
}

progress(taskToken:string) {
  return this.http.get<any>(AppConfig.settings.api + "/api/waiter/progress/"+ encodeURIComponent(taskToken), { headers: getHttpHeaders() }).toPromise();
}

  constructor(private http: HttpClient) { }
}
