<div id="main" class="wrapper sidebar-lg">
    <div class="app-sidebar" data-active-color="white" [attr.data-background-color]="sidebarbgcolor" data-image="assets/img/sidebar-bg/01.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <div class="main-content">
            <div #qsewrapper class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    <!--   <app-notification-sidebar></app-notification-sidebar> -->
  <!--  <app-customizer></app-customizer> -->
</div>