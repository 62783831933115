import { Injectable } from '@angular/core';
import { UserService, adminInformation, userInformation, QSE_USER_ID, QSE_USER_TYPE, QSE_LAST_SITE } from 'app/services/user.service';
import { userHeaderInfo } from 'app/services/commons';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt'
import { AppConfig } from 'app/app.config';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Access-Control-Max-Age': '3600',
      "X-Requested-With": "XMLHttpRequest",
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With,Authorization,content-type',
      'Access-Control-Allow-Credentials': "true"
    }
  )
};

@Injectable({ providedIn: 'root' })
export class AuthService {

  static AUTH_TOKEN = '/oauth/token';

  constructor(private http: HttpClient, private jwtHelperService: JwtHelperService, private userService: UserService) { }


  isAdminUserPersisted(): boolean {
    return this.userService.isAdminUserPersisted();
  }

  isUtilisateurUserPersisted(): boolean {
    return this.userService.isUtilisateurUserPersisted();
  }




  login(username: string, password: string): Observable<any> {
    //  console.log("POST:" + AppConfig.settings.api + AuthService.AUTH_TOKEN);
    return this.http.post(AppConfig.settings.api + AuthService.AUTH_TOKEN, {
      username,
      password
    }, httpOptions);
  }

  loginAdmin(username: string, password: string): Observable<any> {
    // console.log("POST:" + AppConfig.settings.api + AuthService.AUTH_TOKEN+"admin");
    return this.http.post(AppConfig.settings.api + AuthService.AUTH_TOKEN + "admin", {
      username,
      password
    }, httpOptions);
  }
  loginStealth(username: string, password: string): Observable<any> {
    // console.log("POST:" + AppConfig.settings.api + AuthService.AUTH_TOKEN+"admin");
    return this.http.post(AppConfig.settings.api + AuthService.AUTH_TOKEN + "stealth", {
      username,
      password
    }, httpOptions);
  }


}
