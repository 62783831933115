import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpClient } from '@angular/common/http';
import { getHttpHeaders, userHeaderInfo } from './commons';
import { AppConfig } from 'app/app.config';
import { Idle } from '@ng-idle/core';
import { TOKEN_AUTH_USERNAME, TOKEN_KEY, USER_KEY } from './token-storage.service';


export let adminInformation: any = {};
export let userInformation: any = {};
export const QSE_USER_TYPE: string = "QSE_USER_TYPE";
export const QSE_USER_ID: string = "QSE_USER_ID";
export const QSE_USER_INFO: string = "QSE_USER_INFO";
export const QSE_GRP: string = "QSE_GRP";
export const LAST_GRP: string = "LAST_GRP";
export const QSE_LAST_SITE: string = "QSE_LAST_SITE";
export const QSE_LAST_DIVISION: string = "QSE_LAST_DIVISION";
export const QSE_LAST_LANGUE: string = "QSE_LAST_LANGUE";
export const QSE_LAST_AUDIT: string = "QSE_LAST_AUDIT";
export const QSE_SITE_CONTRAT: string = "QSE_SITE_CTRT";
export const QSE_CLIENT: string = "QSE_CLIENT";
export const QSE_PROFILS_COPIES: string = "QSE_PROFILS_COPIES";
export const QSE_TEXTES_DANS_COLLECTEUR: string = "QSE_TEXTES_DANS_COLLECTEUR";

@Injectable({  providedIn: 'root' })
export class UserService {

  accessToken: string;
 // isAdmin: boolean;




  definirParametre(id: any, clef: string, valeur: string) {
    // Mettre à jour ou créer (pris en charge côté serveur)
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/" + encodeURIComponent(id), { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
  }


  definirValeurDernierSiteUtilisateur(user_id: any, site_id: any) {
    // Mettre à jour ou créer (pris en charge côté serveur)
  //  if(!user_id) return;
    var clef: string = "U" + user_id + "_dernier_site";
    sessionStorage.setItem(QSE_LAST_SITE, site_id);
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/" + encodeURIComponent("0"), { clef: clef, valeur: site_id }, { headers: getHttpHeaders() }).toPromise();
  }

  obtenirValeurDernierSiteUtilisateur(user_id: any) {
    var clef: string = "U" + user_id + "_dernier_site";
    return this.http.get<any>(AppConfig.settings.api + "/api/parametre/?filter=" + encodeURIComponent(clef), { headers: getHttpHeaders() }).toPromise();
  }

  obtenirValeurDerniereLangueUtilisateur(user_id: any) {
    var clef: string = "U" + user_id + "_derniere_langue";
    return this.http.get<any>(AppConfig.settings.api + "/api/parametre/?filter=" + encodeURIComponent(clef), { headers: getHttpHeaders() }).toPromise();
  }

  definirValeurDerniereLangueUtilisateur(user_id: any, langue : any) {
    var clef: string = "U" + user_id + "_derniere_langue";
    sessionStorage.setItem(QSE_LAST_LANGUE, langue);
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/" + encodeURIComponent("0"), { clef: clef, valeur: langue }, { headers: getHttpHeaders() }).toPromise();
  }



 verifierDerniereNotificationsPourAdmin(admin_id:any) {
  return this.http.get<any>(AppConfig.settings.api + "/api/administrateur/navbarnotifs/" + encodeURIComponent(admin_id), { headers: getHttpHeaders() }).toPromise();
 }

 verifierDerniereNotificationsPourClient(user_id:any, site_id: any) {
  return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/navbarnotifs/" + encodeURIComponent(site_id) + "/" + user_id, { headers: getHttpHeaders() }).toPromise();
}

marquerDernierAccesClocheNotif(user_id: any, site_id: any) {
  var clef: string = "U" + user_id + "S" + site_id + "_DACCES_CLOCHENOTIF";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}

marquerDernierAccesPageAccueilClient(user_id: any, site_id: any) {
  var clef: string = "U" + user_id + "S" + site_id + "_DACCES_ACCUEIL";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}

marquerDernierAccesPageSupportClient(user_id: any, site_id: any) {
  var clef: string = "U" + user_id + "S" + site_id + "_DACCES_SUPPORT";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}


  constructor(private jwtHelperService: JwtHelperService, private http: HttpClient,   private idle: Idle) {
  }

  logout() {
    this.idle.stop();
   // console.log("in > UserService:logout");
    this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/logout", { headers: getHttpHeaders() }).toPromise().then(response => { }).catch(function (err) {
      console.log(err);
    });
    this.accessToken = null;
    userInformation = {};
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(QSE_USER_INFO);
    sessionStorage.removeItem(QSE_USER_TYPE);
    sessionStorage.removeItem(QSE_USER_ID);
    sessionStorage.removeItem(QSE_LAST_SITE);
    sessionStorage.removeItem(QSE_LAST_DIVISION);
    sessionStorage.removeItem(QSE_GRP);
    sessionStorage.removeItem(QSE_LAST_AUDIT);
    sessionStorage.removeItem(QSE_CLIENT);
    sessionStorage.removeItem(QSE_SITE_CONTRAT);

    sessionStorage.removeItem("adminAsUser")
    sessionStorage.removeItem("adminAsUserId")
   
  //  console.log("sessionStorage cleaned / logout");
    
  }


  


  isAdminUserPersisted(): boolean {

    var ut = sessionStorage.getItem(QSE_USER_TYPE);
    var token = sessionStorage.getItem(TOKEN_KEY);
    return token && ut == 'A';
  }

  isUtilisateurUserPersisted(): boolean {

    var ut = sessionStorage.getItem(QSE_USER_TYPE);
    var token = sessionStorage.getItem(TOKEN_KEY);
    return token && (ut == 'U' || ut == 'S');
  }



  isUser(): boolean {
    var ut = sessionStorage.getItem(QSE_USER_TYPE);
    return this.accessToken && (ut == 'U' || ut == 'S');
  }


  forgotPassword(email: string, language: string) {
    return this.http.post<any>(AppConfig.settings.api + "/oauth/demandenmdp" , { email: email, language: language }, { headers: getHttpHeaders() }).toPromise();
  }


  submitVerifCoded(email: string, code: string) {
    return this.http.post<any>(AppConfig.settings.api + "/oauth/demandenmdp/code", { username: email, password: code }, { headers: getHttpHeaders() }).toPromise()
  }

  submitNewPassword(email: string, mdp: string ) {
    return this.http.post<any>(AppConfig.settings.api + "/oauth/demandenmdp/def" , { username: email, password:  mdp }, { headers: getHttpHeaders() }).toPromise();
  }

}
