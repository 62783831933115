import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { ADMIN_ROUTES, CLIENT_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth.guard';
import { AdminAuthGuard } from './shared/auth/admin-auth.guard';
import { SearchEngineComponent } from './search-engine/search-engine.component';

const appRoutes: Routes = [



  { path: '', component: FullLayoutComponent, data: { title: 'qseapp' }, children: CLIENT_ROUTES, canActivate: [AuthGuard] },
  { path: '',
    redirectTo: '/admin-accueil',
    pathMatch: 'full' },

  { path: '',
    redirectTo: '/accueil',
    pathMatch: 'full' },
  { path: '', component: FullLayoutComponent, data: { title: 'ADMINISTRATION' }, children: ADMIN_ROUTES, canActivate: [AdminAuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'pages' }, children: CONTENT_ROUTES },
  { path: 'search-engine', component: SearchEngineComponent},
  // { path: '', redirectTo: '/search-engine', component: SearchEngineComponent, data: { title: 'search-engine' }, children: CONTENT_ROUTES},

  // { path: '', redirectTo: '/admin-accueil', pathMatch: 'full' , canActivate: [AdminAuthGuard]},
  // { path: '', component: FullLayoutComponent, data: { title: 'demo' }, children: DEMO_ROUTES },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    enableTracing: false,
  //  relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
