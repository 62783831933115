<!-- Navbar (Header) Starts -->
<nav class="navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header large">

            <div ngClass="{{titleActionsClass()}}">
                <div *ngIf="userUI" class="client-label"><i class="fa fa-building-o client-icon"></i>{{nomClient}}</div>

                <div class="navbar-container">
                    <div id="navbarSupportedContent">
                        <ul class="navbar-nav">

                            <li class="nav-item mr-2">
                                <a href="javascript:;" class="navbar-toggle d-lg-none float-left nav-link" id="cz-compact-menu" (click)="ToggleCompact()" data-toggle="collapsed">
                                    <i class="{{toggleCompact}} font-medium-3 blue-grey darken-4"></i>
                                </a> 
                            </li>
                            <li class="nav-item mr-2">
                                <a href="javascript:;" class="nav-link" id="navbar-fullscreen" appToggleFullscreen
                                    (click)="ToggleClass()">
                                    <i class="{{toggleClass}} font-medium-3 blue-grey darken-4"></i>
                                    <p class="d-none">fullscreen</p>
                                </a>
                            </li>

                            <!--
                    <li class="nav-item" ngbDropdown placement="bottom-right">
                        <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
                            <i class="ft-flag font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">Language</p>
                        </a>
                        <div  ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang menuLangage">
                            <a  class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('fr')">
                                <img src="./assets/img/flags/fr.png" alt="Français" class="langimg">
                                <span>Français</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('en')">
                                <img src="./assets/img/flags/uk.png" alt="English" class="langimg">
                                <span>English</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('it')">
                                <img src="./assets/img/flags/it.png" alt="Italiano" class="langimg">
                                <span>Italiano</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('nl')">
                                <img src="./assets/img/flags/nl.png" alt="Dutch" class="langimg">
                                <span>Dutch</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('de')">
                                <img src="./assets/img/flags/de.png" alt="Deutsch" class="langimg">
                                <span>Deutsch</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('es')">
                                <img src="./assets/img/flags/es.png" alt="Español" class="langimg">
                                <span>Español</span>
                            </a>
                         </div>
                    </li>
                -->

                            <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
                                <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language"
                                    id="dropdown-flag" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img
                                        class="langimg selected-flag" [src]="selectedLanguageFlag" alt="flag" /></a>
                                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left"
                                    aria-labelledby="dropdown-flag">

                                    <a class="dropdown-item" href="javascript:;" data-language="fr"
                                        (click)="ChangeLanguage('fr')">
                                        <img class="langimg mr-2" src="./assets/img/flags/fr.png" alt="flag" />
                                        <span class="font-small-3">Français</span></a>

                                    <a class="dropdown-item" href="javascript:;" data-language="nl"
                                        (click)="ChangeLanguage('nl')">
                                        <img class="langimg mr-2" src="./assets/img/flags/nl.png" alt="flag" /><span
                                            class="font-small-3">Nederlands</span></a>

                                    <a class="dropdown-item" href="javascript:;" data-language="en"
                                        (click)="ChangeLanguage('en')">
                                        <img class="langimg mr-2" src="./assets/img/flags/gb.png" alt="flag" />
                                        <span class="font-small-3">English</span></a>
                                    <!--


                          <a class="dropdown-item" href="javascript:;"
                        data-language="pt" (click)="ChangeLanguage('it')"><img class="langimg mr-2"
                          src="./assets/img/flags/it.png" alt="flag" />
                          <span class="font-small-3">Italiano</span></a>

                          <a class="dropdown-item" href="javascript:;" data-language="es" (click)="ChangeLanguage('es')">
                              <img class="langimg mr-2" src="./assets/img/flags/es.png"
                            alt="flag" />
                            <span class="font-small-3">Español</span></a>

                      <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')"><img
                          class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" /><span
                          class="font-small-3">Deutsch</span></a>

                          -->
                                </div>


                            </li>



                            <li class="nav-item" ngbDropdown placement="bottom-left">
                                <a href="javascript:;" (click)="NotifClicked()" class="nav-link position-relative"
                                    id="dropdownBasic2" ngbDropdownToggle>
                                    <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                                    <span *ngIf="notifDataSource.length>0"
                                        class="notification badge badge-pill badge-info">{{notifDataSource.length}}</span>
                                    <p class="d-none">Notifications</p>
                                </a>

                                <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                                    <div class="noti-list">
                                        <a *ngFor="let noti of notifDataSource" href="{{notifTypeLink[noti.type-1]}}"
                                            class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                            <i
                                                class="ft-bell {{notifTypeCSS[noti.type-1]}} float-left d-block font-large-1 mt-1 mr-2"></i>
                                            <span class="noti-wrapper">
                                                <span
                                                    class="noti-title line-height-1 d-block text-bold-400 {{notifTypeCSS[noti.type-1]}}">{{notifType[noti.type-1]}}</span>
                                                <span class="noti-text">{{noti.message}}</span>
                                            </span>
                                        </a>

                                    </div>

                                </div>

                            </li>
                            <li class="nav-item" ngbDropdown placement="bottom-left">
                                <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                                    <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                                    <p class="d-none">Utilisateur</p>
                                </a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="logout">
                                    <!--
                            <a class="dropdown-item py-1" routerLink="/pages/profile">
                                <i class="ft-edit mr-2"></i>
                                <span>My Profile</span>
                            </a>
                            <a class="dropdown-item py-1" routerLink="/inbox">
                                <i class="ft-mail mr-2"></i>
                                <span>My Inbox</span>
                            </a>
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-settings mr-2"></i>
                                <span>Settings</span>
                            </a>
                        -->
                                    <div class="user-name">{{infoUser.prenom}} {{infoUser.nom}}<br>({{droits}})</div>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="logout()">
                                        <i class="ft-power mr-2"></i>
                                        <span>{{'navbar-na*deconnect'|translate}}</span>
                                    </a>
                                </div>
                            </li>
                            <!--
                    <li class="nav-item">
                        <a class="nav-link position-relative notification-sidebar-toggle">
                            <i class="ft-align-left font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">Notifications Sidebar</p>
                        </a>
                    </li>
                -->
                        </ul>
                    </div>
                </div>
            </div>



            <div *ngIf="userUI" class="client-site">
                <div class="choixsite">
                    <div class="dx-field-label site-label">{{changeLabel}}</div>
                    <div *ngIf="isGroup" class="dx-field-value group">
                        <dx-select-box name="client" #clientSelector id="clientSelector" [value]="selectClient"
                            placeholder="Choisir une division" (onValueChanged)="clientSelector_onValueChanged($event)"
                            width="250px" [searchEnabled]="true" [items]="clientDatasource" displayExpr="label"
                            valueExpr="id">
                        </dx-select-box>
                    </div>
                    <div class="dx-field-value">
                        <dx-select-box name="site" #siteSelector id="siteSelector" [value]="selectSite"
                            placeholder="Choisir un site..." (onValueChanged)="siteSelector_onValueChanged($event)"
                            width="400px" [searchEnabled]="true" [items]="siteDatasource" displayExpr="label"
                            valueExpr="id">
                        </dx-select-box>
                    </div>
                </div>
            </div>
        </div>

    </div>
</nav>