import { Component, HostBinding } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { QSE_USER_INFO } from 'app/services/user.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date();
    showCR: boolean = true;
    version : string = AppConfig.settings.version;
    constructor() {
        var QUI = JSON.parse(sessionStorage.getItem(QSE_USER_INFO));
        if(QUI.partenaireInfo!=null) {
            this.showCR = false;
        }

    }
}
